"use strict";
var _a, _b;
Object.defineProperty(exports, "__esModule", { value: true });
exports.getCachedJson = exports.getAllProducts = exports.getProduct = void 0;
var isStaging = location.origin.includes('.stag.') ||
    location.origin.includes('localhost') ||
    location.origin.includes('.hlx.') ||
    location.origin.includes('.aem.');
var API_ONE_PRODUCT = process.env.API_ONE_PRODUCT || '';
var API_KEY = (_a = (isStaging ? process.env.ROD_API_KEY : process.env.API_KEY)) !== null && _a !== void 0 ? _a : '';
var APPLICATION_ID = (_b = (isStaging ? process.env.ROD_APPLICATION_ID : process.env.APPLICATION_ID)) !== null && _b !== void 0 ? _b : '';
var headers = new Headers();
headers.append('X-Header-AppId', APPLICATION_ID);
headers.append('X-Header-ApiKey', API_KEY);
headers.append('Content-Type', 'application/json');
if (isStaging) {
    headers.append('aws-cf-cd-onecx-cache', 'disabled');
    headers.append('x-environment', 'development');
}
var getProduct = function (id, locale) {
    return fetch("".concat(API_ONE_PRODUCT, "/").concat(id, "/data/").concat(locale), {
        headers: headers,
        method: 'GET'
    }).then(function (response) {
        if (!response.ok) {
            return Promise.reject(new Error("GetProduct request failed: ".concat(response.statusText)));
        }
        return response.json();
    });
};
exports.getProduct = getProduct;
var getAllProducts = function (locale, category) {
    var search = category ? "?category=".concat(category) : '';
    return fetch("".concat(API_ONE_PRODUCT.replace('v1', 'v2'), "/all/").concat(locale).concat(search), {
        headers: headers,
        method: 'GET'
    }).then(function (response) {
        if (!response.ok) {
            return Promise.reject(new Error("GetAllProducts request failed: ".concat(response.statusText)));
        }
        return response.json();
    });
};
exports.getAllProducts = getAllProducts;
var getCachedJson = function (url) {
    return fetch(url, { headers: headers, method: 'GET' }).then(function (response) {
        if (!response.ok) {
            return Promise.reject(new Error('Failed to fetch cached JSON!'));
        }
        return response.json();
    });
};
exports.getCachedJson = getCachedJson;
