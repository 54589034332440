"use strict";
var _a, _b, _c, _d;
Object.defineProperty(exports, "__esModule", { value: true });
exports.checkStatus = exports.getRender = void 0;
var API_ONE_RODMANAGER = process.env.API_ONE_RODMANAGER;
var API_KEY = (_b = (_a = process.env.ROD_API_KEY) !== null && _a !== void 0 ? _a : process.env.API_KEY) !== null && _b !== void 0 ? _b : '';
var APPLICATION_ID = (_d = (_c = process.env.ROD_APPLICATION_ID) !== null && _c !== void 0 ? _c : process.env.APPLICATION_ID) !== null && _d !== void 0 ? _d : '';
var getRender = function (request, appId) {
    var headers = new Headers();
    headers.append('X-Header-AppId', APPLICATION_ID);
    headers.append('X-Header-ApiKey', API_KEY);
    headers.append('Content-Type', 'application/json');
    return fetch("".concat(API_ONE_RODMANAGER, "/image-provider/get-render"), {
        headers: headers,
        method: 'POST',
        body: JSON.stringify(request)
    }).then(function (response) {
        if (!response.ok) {
            return Promise.reject(new Error("GetRender request failed: ".concat(response.statusText)));
        }
        return response.json();
    });
};
exports.getRender = getRender;
var checkStatus = function (request, appId) {
    var headers = new Headers();
    headers.append('X-Header-AppId', APPLICATION_ID);
    headers.append('X-Header-ApiKey', appId !== null && appId !== void 0 ? appId : API_KEY);
    headers.append('Content-Type', 'application/json');
    return fetch("".concat(API_ONE_RODMANAGER, "/image-provider/check-status"), {
        headers: headers,
        method: 'POST',
        body: JSON.stringify(request)
    }).then(function (response) {
        if (!response.ok) {
            return Promise.reject(new Error("GetStatus request failed: ".concat(response.statusText)));
        }
        return response.json();
    });
};
exports.checkStatus = checkStatus;
