"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var sortModels = function (a, b) {
    if (a.key.startsWith('vnr')) {
        if (b.name === 'vnr_300t') {
            return -1;
        }
        if (a.name < b.name) {
            return -1;
        }
        if (b.name < a.name) {
            return 1;
        }
        return 0;
    }
    if (a.name > b.name) {
        return -1;
    }
    if (b.name > a.name) {
        return 1;
    }
    return 0;
};
exports.default = sortModels;
