"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DependencyType = void 0;
var DependencyType;
(function (DependencyType) {
    DependencyType["Exclude"] = "exclude";
    DependencyType["Include"] = "include";
    DependencyType["DefaultOption"] = "default";
    DependencyType["Undefined"] = "undefined";
})(DependencyType = exports.DependencyType || (exports.DependencyType = {}));
