"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.usePageData = void 0;
var react_1 = __importStar(require("react"));
var OneApplication_1 = require("~clients/OneApplication");
var OneProduct_1 = require("~clients/OneProduct");
var configUrl_1 = require("~utils/configUrl");
var react_router_dom_1 = require("react-router-dom");
var PageDataContext = react_1.default.createContext({
    sections: {},
    sectionName: function (section) { return ''; },
    sectionLabel: function (section, key) { return ''; },
    sectionTitle: function (section) { return ''; },
    sectionKeys: function (section) { return ({}); },
    isLoading: false,
    isProductLoaded: {},
    products: {},
    hasError: false
});
var usePageData = function () { return react_1.default.useContext(PageDataContext); };
exports.usePageData = usePageData;
/**
 * @category Context
 */
var PageDataContextProvider = function (_a) {
    var children = _a.children;
    /* States */
    var _b = (0, react_1.useState)({}), appData = _b[0], setAppData = _b[1];
    var _c = (0, react_1.useState)({}), products = _c[0], setProducts = _c[1];
    var _d = (0, react_1.useState)(true), isProductsLoading = _d[0], setProductsLoading = _d[1];
    var _e = (0, react_1.useState)(true), isDataLoading = _e[0], setDataLoading = _e[1];
    var _f = (0, react_1.useState)(false), isInitialzied = _f[0], setIsInitialized = _f[1];
    var _g = (0, react_1.useState)(false), hasError = _g[0], setHasError = _g[1];
    var _h = (0, react_1.useState)(null), series = _h[0], setSeries = _h[1];
    var searchParams = (0, react_router_dom_1.useSearchParams)()[0];
    (0, react_1.useEffect)(function () {
        if (!series) {
            var configParam = searchParams.get('config');
            if (configParam) {
                if (configParam.length < 10) {
                    (0, configUrl_1.loadConfigString)(configParam, function (data, error) {
                        if (data) {
                            setSeries(data.model.split('_')[0]);
                        }
                        else {
                            setSeries(location.href.includes('vnr') ? 'vnr' : 'vnl');
                        }
                    });
                }
                else {
                    var configStr = (0, configUrl_1.urlToConfig)(configParam);
                    setSeries(configStr[0] > 9 ? 'vnr' : 'vnl');
                }
            }
            else {
                setSeries(location.href.includes('vnr') ? 'vnr' : 'vnl');
            }
            return;
        }
        if (!isInitialzied && series) {
            setIsInitialized(true);
            setProductsLoading(true);
            (0, OneProduct_1.getAllProducts)('en', series)
                .then(function (response) {
                if (response.success) {
                    var newProducts_1 = {};
                    response.data.forEach(function (product) { return (newProducts_1[product.key] = product); });
                    setProducts(newProducts_1);
                }
                else {
                    setHasError(true);
                }
            })
                .catch(function (error) {
                console.log(error);
                setHasError(true);
            })
                .finally(function () {
                setProductsLoading(false);
            });
            setDataLoading(true);
            (0, OneApplication_1.getAppData)('en')
                .then(function (response) {
                if (response.success) {
                    var newSections_1 = {};
                    response.data.sections.forEach(function (section) {
                        newSections_1[section.section_name] = section;
                    });
                    setAppData(newSections_1);
                }
                else {
                    setHasError(true);
                }
            })
                .catch(function (error) {
                setHasError(true);
                console.log(error);
            })
                .finally(function () {
                setDataLoading(false);
            });
        }
    }, [series]);
    var isProductLoaded = {};
    Object.keys(products).forEach(function (key) { return (isProductLoaded[key] = !!products[key].componentGroups); });
    var value = (0, react_1.useMemo)(function () { return ({
        sections: appData,
        sectionName: function (section) { var _a; return ((_a = appData[section]) === null || _a === void 0 ? void 0 : _a.section_name_localized) || ''; },
        sectionLabel: function (section, key) { var _a; return ((_a = appData[section]) === null || _a === void 0 ? void 0 : _a.key_values[key]) || ''; },
        sectionTitle: function (section) { var _a; return ((_a = appData[section]) === null || _a === void 0 ? void 0 : _a.section_name_localized) || ''; },
        sectionKeys: function (section) { var _a; return ((_a = appData[section]) === null || _a === void 0 ? void 0 : _a.key_values) || ''; },
        isLoading: isDataLoading || isProductsLoading,
        isProductLoaded: isProductLoaded,
        products: products,
        hasError: hasError
    }); }, [appData, isDataLoading, isProductsLoading, hasError, products]);
    return react_1.default.createElement(PageDataContext.Provider, { value: value }, children);
};
exports.default = PageDataContextProvider;
