"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.submitLead = void 0;
var API_SALESFORCE_FORM = (_a = process.env.API_ONE_APPLICATION) !== null && _a !== void 0 ? _a : '';
var API_KEY = process.env.API_KEY || '';
var APPLICATION_ID = process.env.APPLICATION_ID || '';
var submitLead = function (fields, configUrl, imageUrl, series) {
    if (series === void 0) { series = 'vnl'; }
    var searchParams = new URLSearchParams(__assign(__assign({}, fields), { configuratorUrl: configUrl || '', imageUrl: imageUrl || '' }));
    var headers = new Headers();
    headers.append('X-Header-AppId', APPLICATION_ID);
    headers.append('X-Header-ApiKey', API_KEY);
    return fetch("".concat(API_SALESFORCE_FORM, "/api-dispatcher/leads-").concat(series, "?").concat(searchParams.toString()), {
        method: 'POST',
        headers: headers
    }).then(function (response) {
        if (!response.ok) {
            return Promise.reject(new Error("SubmitLead post failed: ".concat(response.statusText)));
        }
    });
};
exports.submitLead = submitLead;
