"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.loadConfigString = exports.saveConfigString = exports.getBaseUrl = exports.urlToConfig = exports.configToUrl = void 0;
var OneHandleManager_1 = require("~clients/OneHandleManager");
var configToUrl = function (features) {
    return encodeURIComponent(btoa(JSON.stringify(features)));
};
exports.configToUrl = configToUrl;
var urlToConfig = function (url) {
    return JSON.parse(atob(decodeURIComponent(url)));
};
exports.urlToConfig = urlToConfig;
var getBaseUrl = function () {
    return window.location.href.split('?')[0];
};
exports.getBaseUrl = getBaseUrl;
var saveConfigString = function (product, config, onComplete, expiresInDays) {
    var configData = {
        model: product.key,
        config: config
    };
    var expireDate = new Date(Date.now() + expiresInDays * 86400000);
    (0, OneHandleManager_1.createHandle)(configData, expireDate)
        .then(function (value) {
        if (value.data) {
            onComplete(value.data);
        }
        else if (value.message) {
            onComplete(null, value.message);
        }
    })
        .catch(function (error) {
        console.error(error);
        onComplete(null, 'Request failed');
    });
};
exports.saveConfigString = saveConfigString;
var loadConfigString = function (handle, onComplete) {
    (0, OneHandleManager_1.getHandle)(handle)
        .then(function (value) {
        if (value.data) {
            onComplete(value.data.configurationData);
        }
        else if (value.message) {
            onComplete(null, value.message);
        }
    })
        .catch(function (error) {
        console.error(error);
        onComplete(null, 'Request failed');
    });
};
exports.loadConfigString = loadConfigString;
